<template>
  <article class="disclosure">
    <h1>Social Media House Rules</h1>
    <p>
      Welcome to Electrify America! The comments posted by users on this site or any other social network page sponsored
      by Electrify America LLC ("User Content") do not necessarily reflect the opinions or ideals of Electrify America,
      its employees or affiliates. We encourage you to leave comments; however, we will review comments and will remove
      any that are inappropriate or offensive at our discretion. Electrify America is not responsible for any User
      Content, especially the accuracy of any statement about our products or product attributes. Electrify America does
      not endorse any opinions expressed by users, either.
    </p>
    <p>
      Below are the rules for communications and general behavior on social-network pages and online channels sponsored
      by Electrify America:
    </p>
    <ol>
      <li>To comply with Electrify America’s Terms of Use;</li>
      <li>that you will not make any indecent or otherwise inappropriate posts;</li>
      <li>that you will not make any libelous, defamatory, bullying, harassing, threatening, or abusive posts;</li>
      <li>that you are the age of 18 or older, unless posting by or with permission of a parent or legal guardian;</li>
      <li>
        that you will not invade another person’s privacy or publicity rights, and will not distribute either yours, or
        anyone else’s personal information;
      </li>
      <li>
        that the User Content:
        <br />
        •{{ '\u00a0 \u00a0 \u00a0\u00a0' }}is your own original work;
        <br />
        •{{ '\u00a0 \u00a0 \u00a0\u00a0' }}does not contain or reference any third party trademarks;
        <br />
        •{{ '\u00a0 \u00a0 \u00a0\u00a0' }}does not infringe the copyright or any other rights of any third party;
        <br />
        •{{ '\u00a0 \u00a0 \u00a0\u00a0' }}does not contain any illegal content or material; and
        <br />
        •{{ '\u00a0 \u00a0 \u00a0\u00a0' }}you have the permission of all persons shown in the User Content to post
        their image and/or their likeness.
      </li>
      <li>
        and acknowledge that the User Content will appear viewable by visitors to our page and may appear on Electrify
        America’s other websites or in its social media network pages.
      </li>
      <li>
        You grant Electrify America a royal-free and fully-paid license to use and display the User Content throughout
        the world in any type of electronic or digital media for commercial, marketing and/or public relations purposes
        in perpetuity.
      </li>
      <li>
        You consent to allow Electrify America to contact you through email (or as otherwise indicated by you) regarding
        any request Electrify America may have related to obtaining permission from you to use the User Content.
      </li>
    </ol>
    <p>Remember: We reserve the right to remove any postings found to be in violation of these rules.</p>
    <p>
      For more information, or to contact customer service, please call our Customer Care Center at 1-833-632-2778, or
      email us at
      <a href="mailto:info@electrifyamerica.com" class="link">info@electrifyamerica.com</a>
    </p>
  </article>
</template>

<script>
export default {
  name: 'social-media-house-rules',
  metaInfo: {
    title: 'Social media house rules | Electrify America',
    meta: [
      {
        name: 'description',
        content:
          'Electrify America encourages you to leave comments on our websites and social media. Learn how we engage with your comments by reading our Social Media House Rules.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/social-media-house-rules/' }],
  },
};
</script>
